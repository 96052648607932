<template>
  <v-list-item>
    <v-list-item-avatar size="32">
      <v-avatar color="grey lighten-3">{{ position }}</v-avatar>
    </v-list-item-avatar>

    <v-list-item-content>
      <div class="d-flex">
        <form-field-text
          :disabled="disabled"
          :value="fPercent"
          :wait="fWait"
          input-type="number"
          label="discount.percent"
          rules="min_value:0|max_value:100"
          @save="onApplyDiscount($event, false)"
          @input:debounce="onSetPercent"
        />

        <form-field-text
          :disabled="disabled"
          :rules="valueRules"
          :value="fValue"
          :wait="fWait"
          class="ml-4"
          input-type="number"
          label="discount.value"
          @input:debounce="onSetValue"
        />
      </div>
    </v-list-item-content>

    <v-list-item-action>
      <v-btn :disabled="disabled" color="red" icon small text @click="onRemove">
        <icon-trash size="1.2rem" />
      </v-btn>
    </v-list-item-action>
  </v-list-item>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import IconTrash from "@/components/icons/IconTrash.vue";
import type { DiscountData } from "@planetadeleste/vue-mc-gw";
import { getPercent, percent, reverseTax } from "@/plugins/helpers";
import { delay, set } from "lodash";
import useMath from "@/composables/math";
import { number } from "mathjs";

export interface DiscountDataExt extends DiscountData {
  limit: number;
  percent: number;
  display_value: number;
  disabled: boolean;
  key: string;
}

const { math } = useMath();

@Component({
  components: { IconTrash },
})
export default class InvoiceInlineItemDiscount extends Vue {
  @Prop(Object) readonly item!: Partial<DiscountDataExt>;

  /**
   * Set disabled, read only component
   * @type {Boolean}
   */
  @Prop(Boolean) readonly disabled!: boolean;

  /**
   * Position number on discount collection
   */
  @Prop(Number) readonly position!: number;

  /**
   * Tax value to display on fixed value field
   */
  @Prop([Number, String]) readonly taxPercent!: number;

  /**
   * Used to display price with/without tax
   */
  @Prop(Boolean) readonly priceWithTax!: boolean;

  cancelDebounce = false;
  fWait = 500;

  get fLimit() {
    return this.item.limit;
  }

  get price(): number {
    if (!this.fLimit) {
      return 0;
    }

    return this.fLimit;
    // return this.priceWithTax && this.taxPercent
    //   ? math.add(this.fLimit, percent(this.fLimit, this.taxPercent))
    //   : this.fLimit;
  }

  get valueRules() {
    return {
      min_value: 0,
      max_value: this.price,
    };
  }

  get fPercent(): number {
    if (!this.item.fixed) {
      return this.item.value || 0;
    }

    return this.price && this.fItemValue
      ? getPercent(this.fItemValue, this.price, true, 3)
      : 0;
  }

  get fValue(): number {
    if (this.item.fixed) {
      return this.fItemValue || 0;
    }

    return this.price && this.item.value
      ? percent(this.item.value, this.price, true, 3)
      : 0;

    // return this.taxPercent && fPrice
    //   ? math.add(fPrice, percent(fPrice, this.taxPercent, true))
    //   : fPrice;
  }

  get fItemValue() {
    const fValue = number(this.item.value);

    if (!this.priceWithTax || !this.item.fixed) {
      return fValue;
    }

    return this.item.name
      ? number(this.item.name)
      : math.add(fValue, percent(fValue, this.taxPercent));
  }

  onRemove() {
    /**
     * Remove inline discount
     */
    this.$emit("remove");
  }

  onApplyDiscount(fValue: number, bFixed: boolean) {
    if (bFixed === true) {
      this.onSetValue(fValue);
    } else {
      this.onSetPercent(fValue);
    }

    if (this.position !== 1) {
      return;
    }

    this.cancelDebounce = true;

    delay(() => {
      this.cancelDebounce = false;
    }, this.fWait + 10);
    delay(() => {
      /**
       * Apply discount
       * @param {DiscountData}
       */
      this.$emit("apply-discount", this.item);
    }, 500);
  }

  onSetDiscountData(fValue: number, bFixed: boolean) {
    let fValueWithoutTax = fValue;

    if (bFixed && this.priceWithTax && this.taxPercent) {
      fValueWithoutTax = math.subtract(
        fValue,
        reverseTax(fValue, this.taxPercent)
      );
    }

    const fPercent =
      bFixed && this.fLimit
        ? getPercent(fValueWithoutTax, this.fLimit, true, 3)
        : fValueWithoutTax;
    this.item.fixed = false;
    this.item.value = fPercent;
    this.item.percent = fPercent;
    set(this.item, "name", fValue);

    this.$emit("apply-discount", this.item);
  }

  onSetValue(fValue: number) {
    if (this.cancelDebounce) {
      return;
    }

    this.onSetDiscountData(fValue, true);
  }

  onSetPercent(fValue: number) {
    if (this.cancelDebounce) {
      return;
    }

    this.onSetDiscountData(fValue, false);
  }
}
</script>
